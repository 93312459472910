@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a,
ul,
li {
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: inherit;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

:root {
    --text: #3b3d3c;
    --contentBackground: rgb(199, 233, 238);
    --inputBackground: white;

    --mainBackground: rgb(152, 150, 201);
    --mainBackgroundGradient: linear-gradient(to bottom, #8f94fb, #6e4ec8);

    --skeleton: rgb(165, 200, 204);
    --bubbles: rgba(163, 177, 216, 0.27);

    --hover: rgb(242, 247, 247);
    --hoverGradient: linear-gradient(
        90deg,
        rgba(242, 247, 247, 0.711922268907563) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    --shadow: 0px 20px 22px rgb(0 0 0 / 15%);
}
