.header {
    display: grid;
    grid-template-columns: 50px 1fr auto;
    grid-template-rows: 1fr;
    grid-template-areas: "logo title data";
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background: var(--contentBackground);
    border-radius: 0 0 6px 6px;
    padding: 10px 30px;
    box-shadow: var(--shadow);
    align-content: center;
    justify-items: start;
    column-gap: 20px;
    transition: all 0.5s ease 0.8ms;
}

.logo {
    grid-area: logo;
    width: 50px;
    height: auto;
}

.logo svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: var(--mainBackground);
}

.title {
    color: var(--text);
    font-weight: 600;
    grid-area: title;
    font-size: 20px;
    margin: 0;
}

.currencyData {
    grid-area: data;
    display: flex;
    align-items: center;
    gap: 60px;
    position: relative;
    min-width: 196px;
    min-height: 1rem;
}

.currencyItem {
    display: flex;
    gap: 5px;
    color: var(--text);
}

.skeleton {
    display: flex;
    gap: 60px;
}

.skeleton div {
    background-color: var(--skeleton);
    opacity: 0.2;
    width: 149px;
    height: 1.5rem;
    border-radius: 6px;
}

@keyframes topRise {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }
}

@media (max-width: 800px) {
    .header {
        grid-template-columns: 50px 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "logo title"
            "logo data";
        gap: 0;
        row-gap: 10px;
        justify-items: center;
    }
}

@media (max-width: 800px) {
    .currencyData {
        gap: 10px;
        flex-direction: column;
        min-height: 3rem;
    }
}

@media (max-height: 700px) {
    .currencyData {
        flex-direction: row;
    }
}

@media (max-width: 300px) {
    .header {
        grid-template-areas:
            "logo title"
            "data data";
    }
    .currencyData {
        flex-direction: column;
    }
}
