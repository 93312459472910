.App {
    text-align: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.headerContainer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.mainContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 40px;
}

@media (max-width: 800px) {
    .mainContainer {
        top: 35%;
        padding: 20px;
    }
}

@media (max-height: 700px) {
    .mainContainer {
        top: 35%;
        transform: translate(-50%, -35%);
    }
}
