.main {
    height: 100%;
    width: 100%;
    background: var(--contentBackground);
    padding: 10px 30px;
    border-radius: 6px;
    box-shadow: var(--shadow);
}

.inputDataList {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.InputDataItem {
    display: flex;
    background-color: var(--inputBackground);
    width: fit-content;
    border-radius: 6px;
    box-shadow: var(--shadow);
}

.input,
.select {
    font-family: "Montserrat", sans-serif;
    background-color: transparent;
    border-radius: 6px;
    border: none;
    padding: 1rem;
    color: var(--text);
}

.select {
    cursor: pointer;
    position: relative;
    min-width: 40px;
    width: 70px;
    transition: all 0.3s ease 0.8ms;
    padding-right: 60px;
}

.select:hover {
    background-color: var(--hover);
}

.input:focus,
.select:focus {
    outline: none;
}

.input:focus {
    background: var(--hover);
    background: var(--hoverGradient);
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.optionWrapper {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: var(--inputBackground);
    border-radius: 6px;
    box-shadow: var(--shadow);

    z-index: 2;
    max-height: 120px;
    overflow: auto;
    transition: all 0.3s ease;
}

.option {
    padding: 10px;
    border-radius: 4px;
    transition: all 0.3s ease 0.8ms;
    color: var(--text);
}
.option:hover {
    background-color: var(--hover);
}

.optionWrapperActive {
    opacity: 1;
    visibility: visible;
    top: 55px;
}

.arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    border: solid var(--text);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: translateY(-50%) rotate(45deg);
    -webkit-transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease;
}
.arrowActive {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

@media (max-width: 800px) {
    .select {
        width: 100px;
    }
    .select:hover {
        background: transparent;
    }
    .select:active {
        background-color: var(--hover);
    }
}

@media (max-width: 380px) {
    .input {
        width: 100px;
    }
}

@media (max-height: 500px) {
    .inputDataList {
        padding: 5px;
        gap: 10px;
    }
    .input,
    .select {
        padding: 5px 10px;
    }
    .select {
        width: 100px;
    }
    .select:hover {
        background: transparent;
    }
    .select:active {
        background-color: var(--hover);
    }
    .optionWrapper {
        top: 29px;
        overflow: auto;
        max-height: 80px;
    }
}
